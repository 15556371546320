<template>
  <div class="row-center captcha_input_wrapper">
    <input
      :class="{ act: activeInput == index }"
      type="password"
      v-for="(item, index) in captchas"
      :key="index"
      v-model="item.num"
      :id="'captcha' + index"
      @input="inputFinash(index)"
      ref="input"
      @focus="adjust(index)"
      @keydown="inputDirection(index)"
      class="captcha_input_box row-center"
      maxlength="1"
      :inputmode="inputmode"
    />
  </div>
</template>

<script>
export default {
  props: {
    inputmode: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      // 当前输入框
      activeInput: 0,
      captchas: [
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.confession();
    }, 100);
  },
  methods: {
    reset() {
      this.activeInput = 0;
      this.captchas = [
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
      ];
      setTimeout(() => {
        this.confession();
      }, 100);
    },
    confession() {
      document.getElementById("captcha0").focus();
    },
    // 自动校准输入顺序
    adjust(index) {
      let dom = document.getElementById("captcha" + this.activeInput);

      if (index !== this.activeInput && dom) {
        dom.focus();
      }
    },
    // 控制前后方向
    inputDirection(index) {
      let val = this.captchas[index].num;
      // 回退键处理
      if (event.keyCode == 8 && val == "") {
        // 重新校准
        let dom = document.getElementById("captcha" + (index - 1));
        index - 1 < 0 ? (this.activeInput = 0) : (this.activeInput = index - 1);
        if (dom) dom.focus();
      }
      if (event.keyCode != 8 && val != "") {
        let dom = document.getElementById("captcha" + (index + 1));
        this.activeInput = index + 1;

        if (dom) dom.focus();
      }
    },
    // 输入框相互联动
    inputFinash(index) {
      //   const regex = /^\d*$/; // 验证输入值是否为数字
      let val = this.captchas[index].num;
      //   if (!regex.test(val)) {
      //     this.captchas[index].num = "";
      //     return;
      //   }
      this.activeInput = val ? index + 1 : index - 1;
      let code1 = this.captchas.map((x) => x.num).join("");
      code1.length == 6 ? this.$emit("finish1", 1) : this.$emit("finish1", 0);

      let dom = document.getElementById("captcha" + this.activeInput);
      if (dom) dom.focus();
      if (index == this.captchas.length - 1) {
        let code = this.captchas.map((x) => x.num).join("");
        if (code.length == 6) {
          this.$emit("finish", code);
        }
      }
    },
  },
};
</script>

<style lang="less">
.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.captcha_input_wrapper {
  width: 100%;
  justify-content: space-between;
}

.captcha_input_box {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #393939;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}

.act {
  border: 1px solid #c9fa5b;
  color: #c9fa5b;
}

@media (max-width: 767px) {
  .captcha_input_box {
    width: 42px;
    height: 42px;
  }
}
</style>
