<template>
  <el-dialog v-model="_show" :append-to-body="true">
    <div class="modal-con">
      <img src="../public/images/new/closeicon.png" alt="" class="close" @click="_show = false" />
      <div class="modal-title">{{ $t("home.desc7") }}</div>
      <div class="modal-desc">{{ $t("node.desc53") }}</div>
      <div class="modal-input">
        <psdinput @finish="finish" @finish1="finish1" ref="PsdInput" v-if="_show" />
      </div>

      <div class="modal-btncon flexcenter" @click="handlerConfirm" v-loading="_loading">
        {{ $t("home.desc20") }}
      </div>
      <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
    </div>
  </el-dialog>
</template>
<script>
import psdinput from "./psdinput.vue";
import { mapState } from "vuex";
import bus from "@/utils/bus";
export default {
  components: {
    psdinput,
  },
  props: {
    show: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      password: "",
      userData: {}
    };
  },
  computed: {
    _loading: {
      get() {
        return this.loading;
      },
      set(e) {
        this.$emit("update:loading", e);
      },
    },
    _show: {
      get() {
        return this.show;
      },
      set(e) {
        if (!e) {
          this.password = "";
        }
        this.$emit("update:show", e);
      },
    },
  },
  mounted() {


  },
  methods: {
    handlerLogpsd() {
      this._show = false;
      if (localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    handlerConfirm() {
      if (this.password == "") {
        this.$message.error(this.$t("planet.desc17"));
        return;
      }
      this.$post(this.URL.member.info, {}).then((res) => {
        if (res.code == 0) {
         let sourceType = res.data.sourceType;
          this.$emit(
            "confirm",
            sourceType == 2 || sourceType == 3
              ? this.password
              : this.common.mdpassword(this.password)
          );
        }
      });

    },
    sendpsd() {
      this.$refs.PsdInput.reset();
      this.password = ''
    },
    finish(val) {
      this.password = val;
    },
    finish1(val) {
      if (val == 0) {
        this.password = "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }

  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .forgottitle {
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

@media (max-width: 767px) {
  .modal-con {
    width: 100%;
    padding: 32px 16px;
  }
}
</style>
